//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

/* Assuming you have a class for the buttons container */
.dashboard-header-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 buttons in a row */
	gap: 10px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
	.dashboard-header-container {
		grid-template-columns: 1fr; /* Stack them on smaller screens */
		justify-content: space-between;
		align-items: center;
		justify-content: center;
	}
}
